import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar/Sidebar";
import Navbar from "./navbar/Navbar";
import { usersColumns } from "./datatable/datatablesource";
import UserDatatable from "./datatable/UserDatatable";
import Chart from "./chart/Chart";
import Widget from "./widget/Widget";
// import ExcelDownloadButton from "./ExcelDownloadButton";
import "./counsellordashboard.css";
import useFetch from "../../hooks/useFetch";
import OrderButton from "../dashboard/Razorpay_button/OrderButton";
import LinkButton from "../dashboard/Razorpay_button/LinkButton";
import axios from "axios";
const Counsellordashboard = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    amount: 0,
  });
  const [submittedData, setSubmittedData] = useState(null);
  const [type, setType] = useState("student");
  const [studentGradeData, setStudentGradeData] = useState([]);
  const [teacherGradeData, setTeacherGradeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const {
    data: userdata,
    loading,
    error,
  } = useFetch("https://api.studentrays.com/api/users/user/count");
  const { data: studentdata } = useFetch(
    "https://api.studentrays.com/api/users/student/count"
  );
  const { data: teacherdata } = useFetch(
    "https://api.studentrays.com/api/users/teacher/count"
  );
  const { data: studentgradedata } = useFetch(
    "https://api.studentrays.com/api/users/student/count/bygrade"
  );
  const { data: teachergradedata } = useFetch(
    "https://api.studentrays.com/api/users/student/count/bygrade"
  );
  const { data: studentpaid } = useFetch(
    "https://api.studentrays.com/api/users/student/paid"
  );
  const { data: studentunpaid } = useFetch(
    "https://api.studentrays.com/api/users/student/unpaid"
  );
  const { data: allData } = useFetch("https://api.studentrays.com/api/users/");
  // console.log("data", data);
  // console.log("data", studentdata);
  // console.log("studentgradedata", studentgradedata);
  // console.log("studentgradedatacount", studentgradedata.gradeCounts);
  // console.log("allData", allData);
  // console.log("studentunpaid", studentunpaid);
  // console.log("studentpaid", studentpaid);
  // console.log("teacherdata", teacherdata);
  // console.log("data", data.userCount);
  // console.log("datastudent", studentdata.studentCount);
  const studentgradedatacountnew = studentgradedata.gradeCounts;
  console.log("new", studentgradedatacountnew);
  const studentgradedatacount = studentgradedatacountnew;
  const keyRange = [6, 7, 8, 9, 10];
  const chartStudentdata = keyRange.map((key) => ({
    name: String(key),
    Total:
      studentgradedatacount &&
      typeof studentgradedatacount === "object" &&
      studentgradedatacount.hasOwnProperty(key)
        ? studentgradedatacount[key]
        : 0,
  }));
  const teachergradedatacountnew = teachergradedata.gradeCounts;
  console.log("new", teachergradedatacountnew);
  const teachergradedatacount = teachergradedatacountnew;
  // const keyRange = [6, 7, 8, 9, 10];
  const chartTeachertdata = keyRange.map((key) => ({
    name: String(key),
    Total:
      teachergradedatacount &&
      typeof teachergradedatacount === "object" &&
      teachergradedatacount.hasOwnProperty(key)
        ? teachergradedatacount[key]
        : 0,
  }));
  // console.log(chartTeacherdata);
  const handleWidgetClick = (type) => {
    setType(type);
    // console.log(`${type} widget button clicked`);
    // console.log("all", allData);

    if (allData) {
      if (type == "student") {
        const filteredStudents = allData.filter(
          (item) => item.isStudent === true
        );
        setFilteredData(filteredStudents);
        console.log("filteredStudents:", filteredStudents);
      } else if (type === "teacher") {
        const filteredTeachers = allData.filter(
          (item) => item.isTeacher === true
        );
        setFilteredData(filteredTeachers);
        console.log("filteredTeachers:", filteredTeachers);
      } else if (type === "paid") {
        const filteredTeachers = allData.filter(
          (item) => item.isStudent === true && item.payment === true
        );
        setFilteredData(filteredTeachers);
        console.log("filteredTeachers:", filteredTeachers);
      } else if (type === "unpaid") {
        const filteredTeachers = allData.filter(
          (item) => item.isStudent === true && item.payment === false
        );
        setFilteredData(filteredTeachers);
        console.log("filteredTeachers:", filteredTeachers);
      } else if (type === "user") {
        setFilteredData(allData);
      }
    }
  };
  useEffect(() => {
    if (allData && allData.length > 0) {
      setType("All Users");
      // const filteredStudents = allData.filter(item => item.isStudent);
      setFilteredData(allData);
      // console.log("filteredStudents:", filteredStudents);
    }
  }, [allData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedData(formData);
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <div className="widgets">
          <Widget
            type="user"
            amount={userdata.userCount}
            onClick={() => handleWidgetClick("user")}
          />
          <Widget
            type="student"
            amount={studentdata.studentCount}
            onClick={() => handleWidgetClick("student")}
          />
          <Widget
            type="teacher"
            amount={teacherdata.teacherCount}
            onClick={() => handleWidgetClick("teacher")}
          />
          <Widget
            type="paid"
            amount={studentpaid.length}
            onClick={() => handleWidgetClick("paid")}
          />
          <Widget
            type="unpaid"
            amount={studentunpaid.length}
            onClick={() => handleWidgetClick("unpaid")}
          />
        </div>
        <UserDatatable columns={usersColumns} data={filteredData} type={type} />
        {/* <div className="charts">
          <Chart
            title="No. of Student vs Grade Chart"
            aspect={2 / 1}
            data={chartStudentdata}
          />
          <Chart
            title="No. of Teacher vs Grade Chart"
            aspect={2 / 1}
            data={chartTeachertdata}
          />
        </div> */}
        <form onSubmit={handleSubmit} style={{ padding: "5rem 2rem" }}>
          <label htmlFor="username">Enter Username:</label>
          <input
            type="text"
            id="username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="email">Enter Email:</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <label htmlFor="amount">Enter Amount to Pay:</label>
          <input
            type="number"
            id="amount"
            value={formData.amount}
            onChange={handleInputChange}
            required
          />
          <button type="submit">Set Amount</button>
          {submittedData && (
            <LinkButton
              username={submittedData.username}
              emaildata={submittedData.email}
              amount={submittedData.amount}
              userdata={submittedData} // Assuming userdata should have additional info, add it here or modify accordingly
            />
          )}
        </form>
        {/* <p>Payment Link: <a href={paymentLink} target="_blank" rel="noopener noreferrer">{paymentLink}</a></p> */}
      {/* <ExcelDownloadButton studentId="670d6b63e64fbbb6bc62d711" /> */}
      {/* <ExcelDownloadButton studentId="670cf64e74ae03b343ccafd7" /> */}
      </div>
    </div>
  );
};

export default Counsellordashboard;
